import {listeMot} from './mot.js';
import {randomItem, getPattern} from './pattern.js'

// Variables globales
let motPoeme=Array(6).fill(null);

const cote={
 "Pluriel":0.5,
 "ParPres":0.2
};

//Fonctions génériques
function clearMotPoeme(){
  motPoeme={
    "nom":[],
    "adjectif":[],
    "verbe":[],
    "adverbe":[],
    "article":[],
    "saison":[]
  };
}

function chance(cote){
  if(Math.random()<cote) {return true;}
  else {return false;}
}

function tirage(table){
  let max=0;
  for(let [element,valeur] of table){
    max+=valeur;
  }

  let cote = Math.round(Math.random()*max);
  let poids=0;
  for(let [element,valeur] of table){
    poids+=valeur;
    if(poids >= cote ) {
      return element;
    }
  }
}

function suivant(actuel,table){
  const liste=Array.from(table.keys());
  const id=liste.indexOf(actuel)+1;
  if(id>liste.length-1){
    return liste[0];
    }
  else {
    return liste[id];
  }
}

export function ucFirst(a){
  return a[0].toUpperCase()+a.substr(1);
}

//Fonctions dédiées
// Liste de mots
function unMot(typeMot, styleMot, unique){
  let listeStyleMot = listeMot[typeMot];
  const egalStyle = (element) => element.style === styleMot;

  if(styleMot!==0){
    listeStyleMot=listeStyleMot.filter(egalStyle);
  }
  let mot=randomItem(listeStyleMot);
  let i=0;
  const egalMot = (element) => element === mot.mot;

  while(motPoeme[typeMot].findIndex(egalMot)!==-1 && i < 10 && unique){
    i++;
    mot=randomItem(listeStyleMot);
  }
  motPoeme[typeMot].push(mot.mot);
  return mot;
}

function unNom(style){
  return unMot("nom",style,true);
}

function unVerbe(style){
  return unMot("verbe",style,true);
}

function unAdjectif(style){
  return unMot("adjectif",style,true);
}

function unArticle(style){
 return unMot("article",0,false);
}

function unAdverbe(style){
  return unMot("adverbe",0,false);
}

function uneSaison(style){
  return unMot("saison",0,false);
}

const poidsTypeLigneUne = new Map([
  ["nc",4],
  ["nac",1],
  ["cn",4],
  ["acn",1],
  ["nn",5],
  ["nv",5],
]);

function ligneUne({nom, adjectif, nom2, verbe, article, adverbe, saison, typeLigne, nombre, ppr }){
  let texte;
  switch (typeLigne) {
    default:
    case "nc": // Vent  beau
      texte=nom.mot+nom[nombre]+" "
      +adjectif.mot+adjectif[nombre+nom.genre];
      break;

    case "nac": // Vent si beau
      texte=nom.mot+nom[nombre]+" "
      +adverbe.mot+" "
      +adjectif.mot+adjectif[nombre+nom.genre];
      break;

    case "cn": // Beau vent
      texte=adjectif.mot+adjectif[nombre+nom.genre]+" "
      +nom.mot+nom[nombre];
      break;

    case "acn": // Si beau vent
      texte=adverbe.mot+" "
      +adjectif.mot+adjectif[nombre+nom.genre]+" "
      +nom.mot+nom[nombre];
      break;

    case "nn": // Vent de navigation
      texte=nom.mot+nom[nombre];
      texte+=(nom2.voyelle!=="v"?" de ":" d'")+nom2.mot+nom2[nombre];
      break;

    case "nv": // Vent qui navigue - Vent  naviguant
      texte=nom.mot+nom[nombre]+" ";
      texte+=(ppr?verbe.mot+verbe.ppr:"qui "+verbe.mot+verbe[nombre]);
      break;
      }
      return texte;
}

const poidsTypeLigneDeux = new Map([
  ["pnvc",5],
  ["pcnv",4],
  ["pacnv",1],
  ["cnpv",4],
  ["acpnv",1],
  ["cnv",5],
  ["pnv",5],
  ["nv",5]
]);

function ligneDeux({nom, adjectif, nom2, verbe, article, adverbe, saison, typeLigne, nombre, ppr}){
  let texte;

  switch (typeLigne) {
    default:
    case "pncv": // Le vent si beau souffle
      texte=(nom.article!=="n"?article[nombre+nom.genre+nom.voyelle]:"")
      +nom.mot+nom[nombre]+" "
      +adverbe.mot+" "
      +adjectif.mot+adjectif[nombre+nom.genre]+" "
      +(ppr?verbe.mot+verbe.ppr:verbe.mot+verbe[nombre]);
      break;

    case "pcnv": // le beau vent souffle
      texte=article[nombre+nom.genre+adjectif.voyelle]
      +adjectif.mot+adjectif[nombre+nom.genre+nom.voyelle]+" "
      +nom.mot+nom[nombre]+" "
      +(ppr?verbe.mot+verbe.ppr:verbe.mot+verbe[nombre]);
      break;

    case "pacnv": // Le si beau vent souffle
      texte=article[nombre+nom.genre]+adverbe.mot+" "
      +adjectif.mot+adjectif[nombre+nom.genre+nom.voyelle]+" "
      +nom.mot+nom[nombre]+" "
      +(ppr?verbe.mot+verbe.ppr:verbe.mot+verbe[nombre]);
      break;

    case "acpnv": // Si beau, le vent souffle
      texte=adverbe.mot+" "
      +adjectif.mot+adjectif[nombre+nom.genre]+", "
      +(nom.article!=="n"?article[nombre+nom.genre+nom.voyelle]:"")
      +nom.mot+nom[nombre]+" "
      +(ppr?verbe.mot+verbe.ppr:verbe.mot+verbe[nombre]);
      break;

    case "cpnv": // Beau, le vent souffle
      texte=adjectif.mot+adjectif[nombre+nom.genre]+", "
      +(nom.article!=="n"?article[nombre+nom.genre+nom.voyelle]:"")
      +nom.mot+nom[nombre]+" "
      +(ppr?verbe.mot+verbe.ppr:verbe.mot+verbe[nombre]);
      break;

    case "cnv": // beau vent qui souffle
      texte=adjectif.mot+adjectif[nombre+nom.genre+nom.voyelle]+" "
      +nom.mot+nom[nombre]+" "
      +(ppr?verbe.mot+verbe.ppr:"qui "+verbe.mot+verbe[nombre]);
      break;

    case "pnv": //  le vent soufle
      texte=(nom.article!=="n"?article[nombre+nom.genre+nom.voyelle]:"")
      +nom.mot+nom[nombre]+" "
      +(ppr?verbe.mot+verbe.ppr:verbe.mot+verbe[nombre]);
      break;

    case "nv": // Vent qui souffle - vent soufflant
      texte=nom.mot+nom[nombre]+" qui "
      +(ppr?verbe.mot+verbe.ppr:verbe.mot+verbe[nombre]);
      break;
    }
  return texte;
}

const poidsTypeLigneTrois = new Map([
  ["acs",1],
  ["cs",4],
  ["sac",1],
  ["sc",4],
  ["ns",5],
]);

function ligneTrois({nom, adjectif, nom2, verbe, article, adverbe, saison, typeLigne, nombre, ppr}){
  let texte;

  switch (typeLigne) {
    default:
    case "acs": // Si beau printemps
      texte =adverbe.mot +" "
      +adjectif.mot+adjectif["s"+saison.genre+saison.voyelle] +" "
      +saison.mot;
      break;

    case "cs": // Beau printemps
      texte =adjectif.mot+adjectif["s"+saison.genre+saison.voyelle] +" "
      +saison.mot;
      break;

    case "sac": // Printemps si beau
      texte =saison.mot+" "
      +(adverbe.mot:"")+" "
      +adjectif.mot+adjectif["s"+saison.genre];
      break;

    case "sc": // Printemps si beau
      texte =saison.mot+" "
      +adjectif.mot+adjectif["s"+saison.genre];
      break;

    case "ns": // Neige de printemps
      texte =nom.mot+nom.s+(saison.voyelle!=="v"?" de ":" d'")
      +saison.mot;
      break;
    }
    return texte;
  }

//Fonctons dédiées
// export

function texteLigne(no, motLigne){
  let texte="";
  switch (no) {
    case 1: // Texte ligne une
      texte = ucFirst(ligneUne(motLigne).trim());
    break;
    case 2: // Texte ligne deux
      texte = ucFirst(ligneDeux(motLigne).trim());
    break;
    case 3: // Texte ligne trois
      texte = ucFirst(ligneTrois(motLigne).trim());
    break;
    default:
  }
return texte;
}

export function textePoeme(){
  clearMotPoeme();

  // Ecriture du titre
  const titre = unNom(0);
  const saison=uneSaison(0);
  const titreNombre=(chance(cote.Pluriel)?"p":"s")
  const ligneTitre=ucFirst((titre.mot+titre[titreNombre]+" "+saison.prep+" "+saison.mot).trim())
  let basePoeme = [{
    "titre":{
      "nom": titre,
      "nombre": titreNombre,
    },
    "saison": saison,
  }];

  let textePoeme={
    "id" : titre,
    "ligne" : [ligneTitre,"","",""],
    "pattern" : null,
    "basePoeme":basePoeme
  };
// Choix du pattern du poeme

  const pattern = getPattern(titre, saison);
  const styleMots=pattern.style;
  textePoeme.pattern=pattern;

//Ecriture des lignes

// Ecriture ligne no 1 :
  basePoeme[1]={
    nom: titre,
    adjectif:unAdjectif(styleMots[0]),
    nom2:unNom(styleMots[0]),
    adverbe:unAdverbe(0),
    verbe:unVerbe(styleMots[0]),
    typeLigne:tirage(poidsTypeLigneUne),
    nombre:titreNombre,
    ppr: chance(cote.ParPres)
  };

  // Ecriture ligne no 2 :
  basePoeme[2]={
    nom:unNom(styleMots[1]),
    adjectif:unAdjectif(styleMots[2]),
    verbe:unVerbe(styleMots[3]),
    article:unArticle(0),
    adverbe:unAdverbe(0),
    typeLigne:tirage(poidsTypeLigneDeux),
    nombre:(chance(cote.Pluriel)?"p":"s"),
    ppr: chance(cote.ParPres)
  };

  // Ecriture ligne no 3 : dernière ligne
  basePoeme[3]={
    nom:unNom(styleMots[4]),
    adjectif:unAdjectif(styleMots[4]),
    adverbe:unAdverbe(0),
    saison:saison,
    typeLigne:tirage(poidsTypeLigneTrois),
    nombre:(chance(cote.Pluriel)?"p":"s"),
    ppr: chance(cote.ParPres)
  };

  textePoeme.basePoeme=basePoeme;
    for(let i of [1,2,3]){
      textePoeme.ligne[i]=texteLigne(i,basePoeme[i]);
    }

  return textePoeme;
}

export function versionLigne(no, motLigne){
  let typeLigne;
  switch (no) {
    default:
    case 1: // Modif typeLigne ligne une
      typeLigne=suivant(motLigne.typeLigne,poidsTypeLigneUne);
    break;
    case 2: // Texte ligne deux
      typeLigne=suivant(motLigne.typeLigne,poidsTypeLigneDeux);
    break;
    case 3: // Texte ligne trois
      typeLigne=suivant(motLigne.typeLigne,poidsTypeLigneTrois);
    break;
  }
  motLigne.typeLigne=typeLigne;
  return texteLigne(no, motLigne);
}

export function tempsLigne(no, motLigne){
  motLigne.ppr= motLigne.ppr?false:true;
  return texteLigne(no, motLigne);
}
