import React from 'react';
import ReactDOM from 'react-dom';

import {versionLigne, tempsLigne, textePoeme} from './poeme.js';

import './style.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

function enregistre(tip){
 let url="./data/enregistre.php?tip="+tip;
 fetch(url);
}

function LigneTitre(props){
  return(
    <p className="titre"> {props.text}</p>
  );
}

function Ligne(props){
  return(
    <p className="ligne"
      onWheel={() => props.onWheelAndMove()}
      onClick={() => props.onClickAndTouch()}

      onTouchMove={() => props.onWheelAndMove()}
    >
      {props.text}
    </p>
  );
}

function LigneFin(props){
  return(
    <p className="ligne-fin"
      onWheel={() => props.onWheelAndMove()}
      onClick={() => props.onClickAndTouch()}

      onTouchMove={() => props.onWheelAndMove()}
    >
      {props.text}
    </p>
  );
}

function LigneTheme(props){
  return(
    <p className="theme">
      {props.text}
    </p>
  );
}

function Cache(){
  return (
    <textarea id="cache" className="cacher" readOnly="readOnly" defaultValue="Poeme de Matsu by Inspiration artificielle">

    </textarea>
  )
}

function Bouton(props){
  return(
    <button
      className={"bouton "+props.classe}
      onClick={() => props.onClick()}
      >
      {props.action}
      </button>
  );
}


class AutoText extends React.Component {
  constructor(props){
    super(props);
    const poeme=textePoeme();
    this.state={
        poeme: poeme,
        listeDernier: [{
            poeme: poeme,
        }],
        noDernier: 0,
        nbDernier: 1,

        meilleur:poeme
      };
  }

// Fonctions de traitament des poemes

meilleurPoeme(){
    const poeme=this.state.poeme;
    const meilleur=this.state.meilleur;
    const listeDernier= this.state.listeDernier.concat([{poeme: meilleur,}]);

  this.setState({
    poeme: meilleur,
    listeDernier: listeDernier,
    noDernier: this.state.noDernier+1,
    nbDernier: this.state.nbDernier+1,
  });
}

dernierPoeme(){
  const noDernier= (this.state.noDernier===0)? this.state.nbDernier-1 : this.state.noDernier-1;
  const dernier=this.state.listeDernier[noDernier].poeme;

  this.setState({
    poeme: dernier,
    noDernier:noDernier,
  });
}

nouveauPoeme(){
    const poeme=textePoeme();
    const listeDernier= this.state.listeDernier.concat([{poeme: poeme,}]);

    this.setState({
      poeme: poeme,
      listeDernier: listeDernier,
      noDernier:this.state.nbDernier,
      nbDernier: this.state.nbDernier+1,
    });

   enregistre('nouveau');
  }

jaimePoeme(){
   const meilleur=this.state.poeme;
   this.setState({
     meilleur: meilleur
   });

   enregistre('jaime');
 }

  copierPoeme(){
    let texteACopier = document.getElementById("cache");

    texteACopier.value=
      this.state.poeme.ligne[0]+" \n\n"
      +this.state.poeme.ligne[1]+" \n"
      +this.state.poeme.ligne[2]+" \n"
      +this.state.poeme.ligne[3]+" \n \n"
      +"Matsu by Inspiration Artificielle  \n";
    texteACopier.style.display = "block";
    texteACopier.select();

    document.execCommand("copy");
    texteACopier.style.display = "none";
  }

  modifierVersionLigne(no, motLigne){
    let temp_state = this.state;
    temp_state.poeme.ligne[no]=versionLigne(no, motLigne);
    this.setState(temp_state);
  }

  modifierTempsLigne(no, motLigne){
    let temp_state = this.state;
    temp_state.poeme.ligne[no]=tempsLigne(no, motLigne);
    this.setState(temp_state);
  }


  renderPoeme(){
    return (
      <div className="article">
        <LigneTitre text={this.state.poeme.ligne[0]} />
        <div className="poeme">
          <Ligne
            text = { this.state.poeme.ligne[1]}
            onWheelAndMove={()=>this.modifierVersionLigne(1, this.state.poeme.basePoeme[1])}
            onClickAndTouch={()=>this.modifierTempsLigne(1, this.state.poeme.basePoeme[1])}
          />
          <Ligne
            text = { this.state.poeme.ligne[2]}
            onWheelAndMove={()=>this.modifierVersionLigne(2, this.state.poeme.basePoeme[2])}
            onClickAndTouch={()=>this.modifierTempsLigne(2, this.state.poeme.basePoeme[2])}
          />
          <LigneFin
            text = { this.state.poeme.ligne[3]}
            onWheelAndMove={()=>this.modifierVersionLigne(3, this.state.poeme.basePoeme[3])}
            onClickAndTouch={()=>this.modifierTempsLigne(3, this.state.poeme.basePoeme[3])}
          />
          </div>
        <LigneTheme text={this.state.poeme.pattern.theme}/>
      </div>
    );
  }

  render() {
    return (
      <div className="page">
        <div className="banniere">
          <div className="intro">
            <div className="sous-intro">Ecriture automatique</div>
            <div className="sous-intro">by '<em>Inspiration Artificielle</em>'</div>
          </div>
          <div className="entete"><em>Les poèmes de <a href="http://matsu-aquila.fr">Matsu Aquila</a></em></div>
        </div>

        {this.renderPoeme()}

        <div className="groupe_bouton">
          <Bouton classe="fond_vert" action="Meilleur" onClick={()=>this.meilleurPoeme()} />
          <Bouton action="Dernier" onClick={()=>this.dernierPoeme()} />
          <Bouton classe="fond_bleu" action="Nouveau" onClick={()=>this.nouveauPoeme()} />
          <Bouton classe="vert" action="J'aime" onClick={()=>this.jaimePoeme()} />
          <Bouton action="Copier" onClick={()=>this.copierPoeme()} />
        </div>
        <Cache />
    </div>
    );
    }
}


ReactDOM.render(
<AutoText/>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
