// Variables globales
// Liste de mots
  let listeNom = [
    {mot:"écume", s:"", p:"s", genre:"f",voyelle:"v",article:"o",style:-1},
    {mot:"vague", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:-1},
    {mot:"houle", s:"", p:"s", genre:"f",voyelle:"v",article:"o",style:-1},
    {mot:"flot", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:-1},
    {mot:"lac", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:-1},
    {mot:"mer", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:-1},
    {mot:"océan", s:"", p:"s", genre:"m",voyelle:"v",article:"o",style:-1},
    {mot:"récif", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:-1},
    {mot:"plage", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:1},
    {mot:"ile", s:"", p:"s", genre:"f",voyelle:"v",article:"o",style:1},
    {mot:"presqu'ile", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:1},
    {mot:"départ", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:1},
    {mot:"rocher", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:1},
    {mot:"terre", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:1},
    {mot:"horizon", s:"", p:"s", genre:"m",voyelle:"v",article:"o",style:1},
    {mot:"oiseau", s:"", p:"x", genre:"m",voyelle:"v",article:"o",style:1},
    {mot:"mouette", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:1},
    {mot:"cormoran", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:1},
    {mot:"fou", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:1},
    {mot:"sterne", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:1},
    {mot:"cie", s:"l", p:"ux", genre:"m",voyelle:"",article:"o",style:1},
    {mot:"azur", s:"", p:"s", genre:"m",voyelle:"v",article:"o",style:1},
    {mot:"zéphir", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:1},
    {mot:"brise", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:1},
    {mot:"souffle", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:1},
    {mot:"vent", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:1},
    {mot:"tempête", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:-1},
    {mot:"rafale", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:1},
    {mot:"bourrasque", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:1},
    {mot:"chaleur", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:1},
    {mot:"fraicheur", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:-1},
    {mot:"neige", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:-1},
    {mot:"grêle", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:-1},
    {mot:"gel", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:-1},
    {mot:"glace", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:-1},
    {mot:"orage", s:"", p:"s", genre:"m",voyelle:"v",article:"o",style:-1},
    {mot:"tonnerre", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:-1},
    {mot:"éclair", s:"", p:"s", genre:"m",voyelle:"v",article:"o",style:-1},
    {mot:"grain", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:-1},
    {mot:"pluie", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:-1},
    {mot:"brume", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:-1},
    {mot:"brouillard", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:-1},
    {mot:"nuage", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:-1},
    {mot:"ondée", s:"", p:"s", genre:"f",voyelle:"v",article:"o",style:-1},
    {mot:"rosée", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:-1},
    {mot:"caprice", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:-1},
    {mot:"parfum", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:1},
    {mot:"songe", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:-1},
    {mot:"rêve", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:-1},
    {mot:"soir", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:-1},
    {mot:"crépuscule", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:-1},
    {mot:"couchant", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:-1},
    {mot:"nuit", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:-1},
    {mot:"aube", s:"", p:"s", genre:"f",voyelle:"v",article:"o",style:1},
    {mot:"aurore", s:"", p:"s", genre:"f",voyelle:"v",article:"o",style:1},
    {mot:"matin", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:1},
    {mot:"jour", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:1},
    {mot:"lumière", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:1},
    {mot:"rayon", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:1},
    {mot:"soleil", s:"", p:"s", genre:"m",voyelle:"",article:"o",style:1},
    {mot:"ombre", s:"", p:"s", genre:"f",voyelle:"v",article:"o",style:-1},
    {mot:"pénombre", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:-1},
    {mot:"lune", s:"", p:"s", genre:"f",voyelle:"",article:"o",style:-1},
    {mot:"Vénus", s:"", p:"", genre:"f",voyelle:"",article:"n",style:-1},
    {mot:"Matsu", s:"", p:"", genre:"f",voyelle:"",article:"n",style:-1},
    {mot:"étoile", s:"", p:"s", genre:"f",voyelle:"v",article:"o",style:1},
    {mot:"abîme", s:"", p:"s", genre:"m",voyelle:"v",article:"n",style:-1},
    {mot:"abysse", s:"", p:"s", genre:"m",voyelle:"v",article:"n",style:-1},
    {mot:"remous", s:"", p:"", genre:"m",voyelle:"",article:"n",style:-1}
  ];

  let listeSaison = [
    {mot:"printemps",genre:"m",voyelle:"",prep:"au",style:1},
    {mot:"été",genre:"m",voyelle:"v",prep:"en",style:1},
    {mot:"automne",genre:"m",voyelle:"v",prep:"en",style:-1},
    {mot:"hiver",genre:"m",voyelle:"v",prep:"en",style:-1},
  ];

  let listeArticle = [
   {mot: "un", sm: "un ", sf: "une ", smv:"un ",sfv:"une ", pm: "des ", pf: "des ", pmv:"des ",pfv:"des ",style:0},
   {mot: "le", sm: "le ", sf: "la ", smv:"l'",sfv:"l'", pm: "les ", pf: "les ", pmv:"les ",pfv:"les ",style:0}
 ];

  let listeAdjectif = [
    {mot:"calme", sm: "", sf: "", smv:"",sfv:"",pm: "s", pf: "s", pmv:"s",pfv:"s",voyelle:"",style:-1},
    {mot:"agité", sm: "", sf: "e", smv:"",sfv:"e",pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"v",style:1},
    {mot:"houleu", sm: "x", sf: "se", smv:"x",sfv:"se",pm: "x", pf: "ses", pmv:"x",pfv:"ses",voyelle:"v",style:1},
    {mot:"vi", sm: "f", sf: "ve", smv:"f",sfv:"ve", pm: "fs", pf: "ves", pmv:"fs",pfv:"ves",voyelle:"v",style:1},
    {mot:"rapide", sm: "", sf: "", smv:"",sfv:"", pm: "s", pf: "s", pmv:"s",pfv:"s",voyelle:"v",style:1},
    {mot:"lent", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"v",style:-1},
    {mot:"endormi", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"v",style:-1},
    {mot:"ensommeillé", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"s",voyelle:"v",style:-1},
    {mot:"assoupi", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"v",style:-1},
    {mot:"abondonné", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"v",style:-1},
    {mot:"éternel", sm: "", sf: "le", smv:"",sfv:"le", pm: "s", pf: "les", pmv:"s",pfv:"les",voyelle:"v",style:-1},
    {mot:"éphémère", sm: "", sf: "", smv:"",sfv:"", pm: "s", pf: "s", pmv:"s",pfv:"s",voyelle:"v",style:1},
    {mot:"éveillé", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"v",style:1},
    {mot:"réveillé", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"v",style:1},
    {mot:"proche", sm: "", sf: "", smv:"",sfv:"", pm: "s", pf: "s", pmv:"s",pfv:"s",voyelle:"",style:1},
    {mot:"lointain", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"",style:-1},
    {mot:"profond", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"",style:-1},
    {mot:"tendre", sm: "", sf: "", smv:"",sfv:"", pm: "s", pf: "s", pmv:"s",pfv:"s",voyelle:"",style:1},
    {mot:"solitaire", sm: "", sf: "", smv:"",sfv:"", pm: "s", pf: "s", pmv:"s",pfv:"s",voyelle:"",style:-1},
    {mot:"vagabond", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"",style:-1},
    {mot:"seul", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"",style:-1},
    {mot:"glacé", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"",style:-1},
    {mot:"glacial", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"",style:-1},
    {mot:"froid", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"",style:-1},
    {mot:"frai", sm: "s", sf: "che", smv:"s",sfv:"che", pm: "s", pf: "ches", pmv:"s",pfv:"ches",voyelle:"",style:-1},
    {mot:"chaud", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"",style:1},
    {mot:"étouffant", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"",style:1},
    {mot:"facile", sm: "", sf: "", smv:"",sfv:"", pm: "s", pf: "s", pmv:"s",pfv:"s",voyelle:"v",style:1},
    {mot:"difficile", sm: "", sf: "", smv:"",sfv:"", pm: "s", pf: "s", pmv:"s",pfv:"s",voyelle:"v",style:-1},
    {mot:"dou", sm: "x", sf: "ce", smv:"x",sfv:"ce", pm: "x", pf: "ces", pmv:"x",pfv:"ces",voyelle:"",style:1},
    {mot:"dur", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"",style:-1},
    {mot:"vie", sm: "ux", sf: "ille", smv:"il",sfv:"ille", pm: "ux", pf: "illes", pmv:"ux",pfv:"illes",voyelle:"",style:-1},
    {mot:"jeune", sm: "", sf: "", smv:"",sfv:"", pm: "s", pf: "s", pmv:"s",pfv:"s",voyelle:"",style:1},
    {mot:"be", sm: "au", sf: "lle", smv:"l",sfv:"lle", pm: "aux", pf: "lles", pmv:"aux",pfv:"lles",voyelle:"",style:1},
    {mot:"sombre", sm: "", sf: "", smv:"",sfv:"", pm: "s", pf: "s", pmv:"s",pfv:"s",voyelle:"",style:-1},
    {mot:"ensoleillé", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"v",style:1},
    {mot:"brumeu", sm: "x", sf: "se", smv:"x",sfv:"se", pm: "x", pf: "ses", pmv:"x",pfv:"ses",voyelle:"",style:-1},
    {mot:"br", sm: "ef", sf: "ève", smv:"ef",sfv:"ève", pm: "efs", pf: "èves", pmv:"efs",pfv:"èves",voyelle:"",style:1},
    {mot:"court", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"",style:1},
    {mot:"long", sm: "", sf: "ue", smv:"",sfv:"ue", pm: "s", pf: "ues", pmv:"s",pfv:"ues",voyelle:"",style:-1},
    {mot:"heureu", sm: "x", sf: "se", smv:"x",sfv:"se", pm: "x", pf: "ses", pmv:"x",pfv:"ses",voyelle:"",style:1},
    {mot:"malheureu", sm: "x", sf: "se", smv:"x",sfv:"se", pm: "x", pf: "ses", pmv:"x",pfv:"ses",voyelle:"",style:-1},
    {mot:"gai", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"",style:1},
    {mot:"triste", sm: "", sf: "", smv:"",sfv:"", pm: "s", pf: "s", pmv:"s",pfv:"s",voyelle:"",style:-1},
    {mot:"songeu", sm: "r", sf: "se", smv:"r",sfv:"se", pm: "rs", pf: "ses", pmv:"rs",pfv:"ses",voyelle:"",style:-1},
    {mot:"maussade", sm: "", sf: "", smv:"",sfv:"", pm: "s", pf: "s", pmv:"s",pfv:"s",voyelle:"",style:-1},
    {mot:"clair", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"",style:1},
    {mot:"matina", sm: "l", sf: "le", smv:"l",sfv:"le", pm: "ux", pf: "les", pmv:"ux",pfv:"les",voyelle:"",style:1},
    {mot:"bleu", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"",style:1},
    {mot:"noir", sm: "", sf: "e", smv:"",sfv:"e", pm: "s", pf: "es", pmv:"s",pfv:"es",voyelle:"",style:-1},
    {mot:"blanc", sm: "", sf: "he", smv:"",sfv:"he", pm: "s", pf: "hes", pmv:"s",pfv:"hes",voyelle:"",style:1},
    {mot:"gris", sm: "", sf: "e", smv:"",sfv:"e", pm: "", pf: "es", pmv:"",pfv:"es",voyelle:"",style:-1}
  ];

  let listeAdverbe = [
    {mot:"si",style:0},
    {mot:"très",style:0},
    {mot:"trop",style:0}
  ];

  let listeVerbe = [
    {mot:"jailli", s:"t", p:"ssent", ppr:"ssant",style:1},
    {mot:"surgi", s:"t", p:"ssent", ppr:"ssant",style:1},
    {mot:"tomb" , s:"e", p:"ent", ppr:"ant",style:-1},
    {mot:"disparai", s:"t", p:"ssent", ppr:"ssant",style:-1},
    {mot:"apparai", s:"t", p:"ssent", ppr:"ssant",style:1},
    {mot:"s'éloign" , s:"e", p:"ent", ppr:"ant",style:-1},
    {mot:"s'en v", s:"ient", p:"iennent", ppr:"enant",style:1},
    {mot:"s'en ", s:"va", p:"vont", ppr:"allant",style:-1},
    {mot:"pleur", s:"e", p:"ent", ppr:"ant",style:-1},
    {mot:"ri", s:"t", p:"ent", ppr:"ant",style:1},
    {mot:"bavard", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"chant", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"cri", s:"e", p:"ent", ppr:"ant",style:-1},
    {mot:"murmur", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"inspir", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"expir", s:"e", p:"ent", ppr:"ant",style:-1},
    {mot:"rêv", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"s'envol" , s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"plan", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"nag", s:"e", p:"ent", ppr:"eant",style:-1},
    {mot:"navigu", s:"e", p:"ent", ppr:"ant",style:-1},
    {mot:"pass", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"plan", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"souffl", s:"e", p:"ent", ppr:"ant",style:-1},
    {mot:"forci", s:"t", p:"ssent", ppr:"ssant",style:1},
    {mot:"brill", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"scintill", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"terni", s:"t", p:"ssent", ppr:"ssant",style:-1},
    {mot:"s'étei", s:"nt", p:"gnent", ppr:"gnant",style:-1},
    {mot:"s'allum", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"s'approch", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"se rapproch", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"nous rapproch", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"m'appell", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"nous appell", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"me réveill", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"se réveill", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"nous réveill", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"m'éveill", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"s'éveill", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"nous éveill", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"m'éclair", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"nous éclair", s:"e", p:"ent", ppr:"ant",style:1},
    {mot:"m'endor" , s:"t", p:"ment", ppr:"mant",style:-1},
    {mot:"nous endor" , s:"t", p:"ment", ppr:"mant",style:-1},
    {mot:"me calm" , s:"e", p:"ent", ppr:"ant",style:-1},
    {mot:"nous calm" , s:"e", p:"ent", ppr:"ant",style:-1},
    {mot:"se calm" , s:"e", p:"ent", ppr:"ant",style:-1},
    {mot:"se bris" , s:"e", p:"ent", ppr:"ant",style:-1},
    {mot:"se repos" , s:"e", p:"ent", ppr:"ant",style:-1},
    {mot:"me repos" , s:"e", p:"ent", ppr:"ant",style:-1},
    {mot:"nous repos" , s:"e", p:"ent", ppr:"ant",style:-1}
  ];

  export let listeMot = {
    "nom": listeNom,
    "saison": listeSaison,
    "article": listeArticle,
    "adjectif": listeAdjectif,
    "adverbe": listeAdverbe,
    "verbe": listeVerbe
  };

  export  let typeMot = new Map([
    ["a","adverbe"],
    ["c","adjectif"],
    ["n", "nom"],
    ["p","article"],
    ["s","saison"],
    ["v","verbe"]
  ]);
