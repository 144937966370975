
const listeStylePattern = [ // liste de [5 x mot [coefTitre, coefSaison]]
    { id:"01n01n0n10",
      theme:"Mélange doux",
      style:[
        {coefTitre:0, coefSaison:1},
        {coefTitre:-1, coefSaison:0},
        {coefTitre:1, coefSaison:-1},
        {coefTitre:0, coefSaison:-1},
        {coefTitre:1, coefSaison:0}
      ]
    },
    { id:"0n0nn00nn0",
      theme:"Mélange fort",
      style:[
      {coefTitre:0, coefSaison:-1},
      {coefTitre:0, coefSaison:-1},
      {coefTitre:-1, coefSaison:0},
      {coefTitre:0, coefSaison:-1},
      {coefTitre:-1, coefSaison:0}
    ]
  },
  { id:"n00nn1n00n",
    theme:"Confrontation",
    style:[
      {coefTitre:-1, coefSaison:0},
      {coefTitre:0, coefSaison:-1},
      {coefTitre:-1, coefSaison:1},
      {coefTitre:-1, coefSaison:0},
      {coefTitre:0, coefSaison:-1}
    ]
  },
  { id:"1001100110",
    theme:"Calme",
    style:[
      {coefTitre:1, coefSaison:0},
      {coefTitre:1, coefSaison:0},
      {coefTitre:0, coefSaison:1},
      {coefTitre:1, coefSaison:0},
      {coefTitre:0, coefSaison:1}
    ]
  },
  { id:"1001100110",
    theme:"Sagesse",
    style:[
      {coefTitre:1, coefSaison:0},
      {coefTitre:0, coefSaison:1},
      {coefTitre:1, coefSaison:0},
      {coefTitre:0, coefSaison:1},
      {coefTitre:1, coefSaison:0}
    ]
  },
  { id:"0110011001",
    theme:"Douceur",
    style:[
      {coefTitre:0, coefSaison:1},
      {coefTitre:1, coefSaison:0},
      {coefTitre:0, coefSaison:1},
      {coefTitre:1, coefSaison:0},
      {coefTitre:0, coefSaison:1}
    ]
  }
  ];

export function randomItem(liste){
  return liste[Math.floor(Math.random() * liste.length)];
}

export function getPattern(titre,saison){
  const styleTitre=titre.style;
  const styleSaison=saison.style;

  const stylePattern= randomItem(listeStylePattern);

  let styleMots={
    id:stylePattern.id,
    theme:stylePattern.theme,
    style:[0,0,0,0,0],
  };
  for(let i=0;i<5;i++){
    styleMots.style[i]=Math.sign(styleTitre*stylePattern.style[i].coefTitre+styleSaison*stylePattern.style[i].coefSaison);
  }

  return styleMots;
}
